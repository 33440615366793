"use client";

import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";
import notFound from "@/images/not-found.png";
import { translationsNotFound } from "@/constants/translations-error-pages";
import { Manrope } from "next/font/google";
import { langFromPathName } from "@/utils/pathname-utils";
import { useEffect } from "react";

const manrope = Manrope({
  weight: ["400", "600"],
  subsets: ["latin"],
  display: "swap",
  variable: "--font-manrope",
});

const NotFound = () => {
  const pathname = usePathname();
  const lang = pathname.split("/")[1] || "en";
  const translation = langFromPathName(pathname, translationsNotFound);

  useEffect(() => {
    document.title = translation.pageTitle || "Not Found";
  }, [translation]);

  return (
    <html lang={lang} className={manrope.variable}>
      <body>
        <div className="flex h-screen flex-col items-center justify-center gap-12 bg-primary lg:flex-row">
          <div className="relative">
            <Image src={notFound} alt="not-found" width={255} />
          </div>
          <div className="flex flex-col items-center gap-6 px-6 text-center text-white lg:w-72 lg:items-start lg:text-start">
            <h3 className="text-4xl font-extrabold">{translation.title}</h3>
            <p>{translation.subtitle}</p>
            <Link href="/" className="btn btn-accent">
              {translation.cta}
            </Link>
          </div>
        </div>
      </body>
    </html>
  );
};

export default NotFound;
